import { services } from "./services"

interface IMenu {
  title: string
  slug: string
  a11y: string
  disabledPath?: string[]
  disabledLocation?: IMenuDisabledLocation[]
  children?: IMenu[]
}
type IMenuDisabledLocation = "footer" | "header" | "nav"
export const menus: IMenu[] = [
  {
    title: "Home",
    slug: "/",
    a11y: "Soleil Clinic",
    disabledPath: [],
    disabledLocation: ["footer"],
  },
  {
    title: "Services",
    slug: "#",
    a11y: "Soleil Services",
    disabledPath: [],
    children: services
      .filter((service) => !service.hidden)
      .map((service) => ({ title: service.name, slug: `/service/${service.slug}`, a11y: service.name, disabledPath: [] })),
  },
  {
    title: "Insights",
    slug: "/insights",
    a11y: "Soleil Insights",
    disabledPath: [],
    disabledLocation: ["header", "footer"],
  },
  {
    title: "After Care",
    slug: "/after-care",
    a11y: "After Care",
    disabledPath: [],
    disabledLocation: ["header"],
  },
  {
    title: "FAQ",
    slug: "/faq",
    a11y: "FAQ",
    disabledPath: [],
    disabledLocation: ["header"],
  },
  {
    title: "Offers",
    slug: "/offers",
    a11y: "Soleil Offers",
    disabledPath: [],
    disabledLocation: [],
  },
  {
    title: "About",
    slug: "/about",
    a11y: "About Soleil Clinic",
    disabledPath: [],
    disabledLocation: ["footer"],
  },
  {
    title: "Contact",
    slug: "/contact",
    a11y: "Soleil Contact",
    disabledPath: [],
    disabledLocation: ["footer"],
  },
]
